import { Component, Vue } from "vue-property-decorator";
import DfContent from "@/vue/domain/content/df-content";
import DfStore from "@/vue/domain/store/df-store";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfShoppingProduct from "@/vue/domain/shopping-product/df-shopping-product";

@Component
export default class DfAppNavigationComponent extends Vue {
  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get isHome(): boolean {
    return this.$route.name === "home-store";
  }

  get isPromotions(): boolean {
    return this.$route.name === "promotions";
  }

  get isNews(): boolean {
    return this.$route.name === "news";
  }

  get isNotifications(): boolean {
    return this.$route.name === "notifications";
  }

  get linkNotificationsIcon(): string {
    return `${Utils.getPublicPath()}/assets/notifications.png`;
  }

  get linkNotificationsOutlineIcon(): string {
    return `${Utils.getPublicPath()}/assets/notifications-outline.png`;
  }

  get isShoppingList(): boolean {
    return this.$route.name === "shopping-list";
  }

  get shoppingList(): Array<DfShoppingProduct> {
    return this.$store.getters.shoppingList;
  }

  get storedPushesList(): Array<any> {
    return this.$store.getters.storedPushesList;
  }

  get pushesNumber(): number {
    const storePush: Array<DfContent> = this.$store.getters.storeContents.filter((content: DfContent) => content.type.code === "PSH");
    const brandPush: Array<DfContent> = this.$store.getters.brandContents.filter((content: DfContent) => content.type.code === "PSH");
    const pushesList: Array<DfContent> = [...storePush, ...brandPush].sort((contentA, contentB) => (contentA.priority < contentB.priority ? 1 : -1));

    return pushesList.filter((push: DfContent) => this.storedPushesList.findIndex((storedPush: any) => storedPush.alias === push.alias) === -1).length;
  }
}
