import { Component, Vue } from "vue-property-decorator";
import DfStore from "@/vue/domain/store/df-store";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfBrand from "@/vue/domain/brand/df-brand";
import DfShoppingProduct from "@/vue/domain/shopping-product/df-shopping-product";

@Component
export default class DfAppNavigationComponent extends Vue {
  private logoRules: any = {
    famila: ["arca/famila", "maxidi/famila", "unicomm/famila"],
    aeo: ["maxidi/aeo"],
    emi: ["gmf/emi"],
    emisfero: ["unicomm/emisfero"],
    galassia: ["maxidi/galassia"],
    mercato: [],
    pan: ["superemme/pan"],
    gulliver: ["alfi/gulliver"],
  };

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get selexAwardsUrl(): string {
    const selexAwardsUrl: string = Utils.getPropertyValue(this.brand, Utils.PROPERTY_SELEX_AWARDS_URL, "STRING", "");
    const selexStoreAlias: string = Utils.getPropertyValue(this.currentStore, Utils.PROPERTY_SELEX_STORE_ALIAS, "STRING");
    return selexStoreAlias ? `${selexAwardsUrl}${selexStoreAlias}/catalogo-premi` : selexAwardsUrl;

    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_SELEX_AWARDS_URL, "STRING", "").replace("$store-alias", this.currentStore.alias);
  }

  get selexPrivateAreaUrl(): string {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_SELEX_PRIVATE_AREA_URL, "STRING", "");
  }

  get selexStoreUrl(): string {
    const selexStoreUrl: string = Utils.getPropertyValue(this.brand, Utils.PROPERTY_SELEX_STORE_URL, "STRING", "");
    const selexStoreAlias: string = Utils.getPropertyValue(this.currentStore, Utils.PROPERTY_SELEX_STORE_ALIAS, "STRING");
    return selexStoreAlias ? `${selexStoreUrl}${selexStoreAlias}/offerte-volantino` : selexStoreUrl;
  }

  get selexStoresUrl(): string {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_SELEX_STORES_URL, "STRING");
  }

  get selexMallsUrl(): string {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_SELEX_MALLS_URL, "STRING");
  }

  get isShoppingList(): boolean {
    return this.$route.name === "shopping-list";
  }

  get shoppingList(): Array<DfShoppingProduct> {
    return this.$store.getters.shoppingList;
  }

  get storesLabel(): string {
    return Utils.getBasePath() === "/selex/emisfero" ? "Ipermercati" : "Negozi";
  }

  private showMyLogo(myLogoName: string): boolean {
    return (this.logoRules[myLogoName] || []).includes(Utils.getApiPath());
  }
}
